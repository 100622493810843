import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  Box,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useGetBuildingQuery } from 'entities/building';
import {
  useGetChessQuery,
  useUpdateChessMutation,
} from 'entities/chess/model/api/ChessService';
import { LotPosition, NewLot } from 'entities/chess/model/types/lotSchema';
import { Entrance, useUpdateEntranceMutation } from 'entities/entrance';
import { useGetObjectQuery, useVersionCheck } from 'entities/object';
import { getRoute } from 'shared/const';
import { useControlPressed } from 'shared/hooks/useControlPressed';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { BgColor, TextColor } from 'shared/theme/colors';
import { theme } from 'shared/theme/theme';
import { LotsGrid } from 'shared/ui/LotsGrid/LotsGrid';
import { Spinner } from 'shared/ui/Spinner';
import { Lot, lotZod } from 'src/entities/chess';
import { ChessboardSkeleton } from './ChessboardSkeleton';
import { EditBar } from './EditBar';
import { FiltersBar } from './FiltersBar';
import { FiltersDialog, LotsFilters } from './FiltersDialog';
import { getFilteredLotsIds } from './lib/getFilteredLotsIds';
import { getLotsDialogOptions } from './lib/getLotsDialogOptions';
import {
  getSelectedLots,
  GridSelection,
  GridSelectionMode,
  sortByPositions,
} from './lib/getSelectedLots';
import { positionsToColspanRowspan } from './lib/positionsToColspanRowspan';
import { toggleSelection } from './lib/toggleSelection';
import { addFloor, deleteFloor } from './lib/updateFloorsCount';
import { updateRisersColspan } from './lib/updateRisersColspan';
import { addRiser, deleteRiser } from './lib/updateRisersCount';

const EDIT_BAR_AND_CHESS_GAP = 12;
const CHESS_MARGIN_BOTTOM = 24;

const initialGridSelection: GridSelection = {
  selectedLots: [],
  selection: [],
  selectionMode: 'lots',
};

type ChessboardProps = {
  isFullscreen: boolean;
  onCloseFullscreen: () => void;
};

export const Chessboard: FC<ChessboardProps> = ({
  isFullscreen,
  onCloseFullscreen,
}) => {
  const { t } = useTranslation();
  const isControlPressed = useControlPressed();

  const { objectId, buildingId, entranceId } = useParams<{
    objectId: string;
    buildingId: string;
    entranceId: string;
  }>();
  const navigate = useNavigate();
  const [openFiltersDialog, setOpenFiltersDialog] = useState<boolean>(false);
  const [lotsFilters, setLotsFilters] = useState<Partial<LotsFilters>>();
  const [filteredLotsIds, setFilteredLotsIds] = useState<number[]>();
  const [gridSelection, setGridSelection] =
    useState<GridSelection>(initialGridSelection);
  const [entrance, setEntrance] = useState<Entrance>();
  const [editBarHeight, setEditBarHeight] = useState<number>();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [updateChess, { isLoading: isChessSaving }] = useUpdateChessMutation();
  const [updateEntrance] = useUpdateEntranceMutation();
  const { showSnackbar } = useSnackbar();

  const { data: object } = useGetObjectQuery(objectId ?? skipToken);
  const { data: building, isLoading: isBuildingFetching } = useGetBuildingQuery(
    buildingId ?? skipToken
  );
  const { data: chess, isLoading: isLotsFetching } = useGetChessQuery(
    entranceId ?? skipToken
  );
  const isSomeLoading = isBuildingFetching || isLotsFetching || isChessSaving;

  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (building && chess) {
      const entrance = building.entrances?.find(
        ({ id }) => id.toString() === entranceId
      );
      if (entrance) {
        // TODO: количество этажей берем из пришедшей шахматки,
        //  т.к. в фениксе шахматки не соответсвуют размерам подъезда
        setEntrance({
          ...entrance,
          floorCount: chess.floors.length.toString(),
          riserCount: chess.risers.length.toString(),
        });
        setGridSelection((currentGridSelection) => ({
          ...currentGridSelection,
          selectedLots: getSelectedLots(
            currentGridSelection.selectionMode,
            currentGridSelection.selection,
            chess.lots
          ),
        }));
      }
    }
  }, [building, chess, entranceId]);

  useEffect(() => {
    if (chess) {
      const filteredLotsIds = getFilteredLotsIds(lotsFilters, chess.lots);
      setFilteredLotsIds(filteredLotsIds);
    }
  }, [chess, lotsFilters]);

  const handleSaveFilters = (newFilters: Partial<LotsFilters>) => {
    if (!chess) {
      return;
    }
    setLotsFilters(newFilters);
    setOpenFiltersDialog(false);
  };

  const clearFilters = () => {
    setLotsFilters(undefined);
    setOpenFiltersDialog(false);
  };

  const deleteFilter = (key: keyof LotsFilters) => {
    const newFilters = { ...lotsFilters };
    delete newFilters[key];

    setLotsFilters(Object.keys(newFilters).length ? newFilters : undefined);
  };

  const select = (
    value: number,
    mode: GridSelectionMode,
    isControlPressed = false
  ) => {
    if (!chess) {
      return;
    }
    let newSelection: number[] = [];

    if (gridSelection.selectionMode != mode || !isControlPressed) {
      newSelection = [value];
    } else {
      newSelection = toggleSelection(value, gridSelection.selection);
    }

    setGridSelection({
      selection: newSelection,
      selectionMode: mode,
      selectedLots: getSelectedLots(mode, newSelection, chess.lots),
    });
  };

  const handleLotClick = (lotId: number) => () => {
    if (!isSomeLoading) {
      select(lotId, 'lots', isControlPressed);
    }
  };

  const clearSelection = () => {
    setGridSelection(initialGridSelection);
  };

  const handleFloorCellClick = (rowIndex: number) => () => {
    select(rowIndex, 'floors', isControlPressed);
  };

  const handleRiserCellClick = (columnIndex: number) => {
    select(columnIndex, 'risers', isControlPressed);
  };

  const handleRiserRename = (columnIndex: number, name: string) => {
    if (entrance && chess) {
      renameRiser(columnIndex, name);
    }
  };

  const handleFloorRename = (rowIndex: number, name: string) => {
    if (entrance && chess) {
      renameFloor(rowIndex, name);
    }
  };

  const renameRiser = async (columnIndex: number, name: string) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (entrance && chess) {
          const cloneRisers = chess.risers.map((riser) => ({ ...riser }));
          cloneRisers[columnIndex - 1].lotNumber = name;

          await updateChess({
            chess: {
              entranceId: entrance.id,
              risers: cloneRisers,
              floors: chess.floors,
              lots: chess.lots,
            },
            objectId: Number(objectId),
            buildingId: Number(buildingId),
          }).unwrap();
          clearSelection();
        }
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const renameFloor = async (rowIndex: number, name: string) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (entrance && chess) {
          const cloneFloors = chess.floors.map((floor) => ({ ...floor }));
          cloneFloors.reverse();
          cloneFloors[rowIndex - 1].lotNumber = name;
          cloneFloors.reverse();

          await updateChess({
            chess: {
              entranceId: entrance.id,
              risers: chess.risers,
              floors: cloneFloors,
              lots: chess.lots,
            },
            objectId: Number(objectId),
            buildingId: Number(buildingId),
          }).unwrap();
          clearSelection();
        }
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleSaveDialogClick = async (changedLots: Lot[]) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (entrance && chess) {
          const updatedLotsIds = changedLots.map(({ id }) => id);
          const sortedLots = [
            ...chess.lots.filter(({ id }) => !updatedLotsIds.includes(id)),
            ...changedLots,
          ].sort(sortByPositions);

          await updateChess({
            chess: {
              entranceId: entrance.id,
              risers: chess.risers,
              floors: chess.floors,
              lots: sortedLots,
            },
            objectId: Number(objectId),
            buildingId: Number(buildingId),
          }).unwrap();
          clearSelection();
        }
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleDisabledChange = async (disabled: boolean) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        const updatedLots = gridSelection.selectedLots.map((lot) => ({
          ...lot,
          disabled: disabled,
        }));
        if (entrance && chess) {
          const updatedLotsIds = updatedLots.map(({ id }) => id);
          const sortedLots = [
            ...chess.lots.filter(({ id }) => !updatedLotsIds.includes(id)),
            ...updatedLots,
          ].sort(sortByPositions);
          // await updateLot({ entranceId: entrance.id, chess: updatedLots[0] });
          await updateChess({
            chess: {
              entranceId: entrance.id,
              risers: chess.risers,
              floors: chess.floors,
              lots: sortedLots,
            },
            objectId: Number(objectId),
            buildingId: Number(buildingId),
          }).unwrap();

          clearSelection();
        }
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleSplitClick = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (!entrance || !chess) {
          return;
        }
        // Копируем текущие лоты и risers.
        const updatedRisers = [...chess.risers];

        const newLots: Partial<NewLot>[] = [];

        gridSelection.selectedLots.forEach((selectedLot) => {
          const { id, ...lotData } = selectedLot;
          const lotPositions =
            selectedLot.positions.length === 1
              ? [selectedLot.positions[0], selectedLot.positions[0]]
              : selectedLot.positions;

          lotPositions.forEach((position) => {
            newLots.push({
              ...lotData,
              positions: [position],
              headId: updatedRisers[position.riser - 1].id,
            });
          });
        });

        const deletedLotsIds = gridSelection.selectedLots.map(({ id }) => id);
        const restLots = chess.lots.filter(
          ({ id }) => !deletedLotsIds.includes(id)
        );
        const updatedLots = [...restLots, ...(newLots as Lot[])];

        updateRisersColspan(
          updatedLots,
          updatedRisers,
          Number(entrance.floorCount)
        );

        const sortedLots: Lot[] = updatedLots
          .map((lot) => ({
            ...lot,
            ...positionsToColspanRowspan(
              lot,
              updatedRisers[
                Math.min(...lot.positions.map((pos) => pos.riser)) - 1
              ],
              updatedLots
            ),
          }))
          .sort(sortByPositions);

        await updateChess({
          chess: {
            entranceId: entrance.id,
            risers: updatedRisers,
            floors: chess.floors,
            lots: sortedLots,
          },
          objectId: Number(objectId),
          buildingId: Number(buildingId),
        }).unwrap();
        clearSelection();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const findMaxValueLot = (lots: Lot[]) => {
    let maxValueLot = lots[0];
    let maxPrice = parseFloat(lots[0].totalPrice || '0');
    let maxArea = parseFloat(lots[0].totalArea || '0');

    for (const lot of lots) {
      const price = parseFloat(lot.totalPrice || '0');
      const area = parseFloat(lot.totalArea || '0');

      if (price > maxPrice) {
        maxPrice = price;
        maxValueLot = lot;
      } else if (price === maxPrice) {
        if (area > maxArea) {
          maxArea = area;
          maxValueLot = lot;
        }
      } else if (maxPrice === 0 && area > maxArea) {
        maxArea = area;
        maxValueLot = lot;
      }
    }
    const { id, ...maxValueLotData } = maxValueLot;
    return maxValueLotData;
  };

  const handleMergeClick = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        // Объединяем все позиции из выбранных лотов в этот лот.
        const newPositions: LotPosition[] = [];
        for (let i = 0; i < gridSelection.selectedLots.length; i++) {
          gridSelection.selectedLots[i].positions.forEach((newPos) => {
            const isDuplicate = newPositions.some(
              (pos) => pos.floor === newPos.floor && pos.riser === newPos.riser
            );

            if (!isDuplicate) {
              newPositions.push(newPos);
            }
          });
        }

        const targetLot: Partial<NewLot> = {
          ...findMaxValueLot(gridSelection.selectedLots),
          type: 'flat',
          disabled: false,
          positions: newPositions,
          headId: gridSelection.selectedLots[0].headId,
        };

        if (entrance && chess) {
          const deletedLotsIds = gridSelection.selectedLots.map(({ id }) => id);
          const updatedLots = [
            ...chess.lots.filter(({ id }) => !deletedLotsIds.includes(id)),
            targetLot as Lot,
          ];

          const updatedRisers = [...chess.risers];
          updateRisersColspan(
            updatedLots,
            updatedRisers,
            Number(entrance.floorCount)
          );

          const sortedLots = updatedLots
            .map((lot) => ({
              ...lot,
              ...positionsToColspanRowspan(
                lot,
                updatedRisers[
                  Math.min(...lot.positions.map((pos) => pos.riser)) - 1
                ],
                updatedLots
              ),
            }))
            .sort(sortByPositions);

          await updateChess({
            chess: {
              entranceId: entrance.id,
              risers: updatedRisers,
              floors: chess.floors,
              lots: sortedLots,
            },
            objectId: Number(objectId),
            buildingId: Number(buildingId),
          }).unwrap();
          clearSelection();
        }
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleCancelDialogClick = () => {
    clearSelection();
  };

  const handleAddRiser = async (addedRiser: number) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (!entrance || !chess) {
          return;
        }
        clearSelection();

        const { updatedRisers, updatedLots } = addRiser(
          chess.lots,
          chess.risers,
          addedRiser,
          Number(entrance.floorCount)
        );

        updateEntrance({
          ...entrance,
          riserCount: (Number(entrance.riserCount) + 1).toString(),
        }).unwrap();

        await updateChess({
          chess: {
            entranceId: entrance.id,
            risers: updatedRisers,
            floors: chess.floors,
            lots: updatedLots,
          },
          objectId: Number(objectId),
          buildingId: Number(buildingId),
        }).unwrap();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleAddFloor = async (addedFloor: number) => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (!entrance || !chess) {
          return;
        }
        clearSelection();

        const { updatedFloors, updatedLots } = addFloor(
          chess.lots,
          chess.floors,
          addedFloor,
          chess.risers
        );

        updateEntrance({
          ...entrance,
          floorCount: (Number(entrance.floorCount) + 1).toString(),
        }).unwrap();

        await updateChess({
          chess: {
            entranceId: entrance.id,
            risers: chess.risers,
            floors: updatedFloors,
            lots: updatedLots,
          },
          objectId: Number(objectId),
          buildingId: Number(buildingId),
        }).unwrap();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleDeleteRiser = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (!entrance || !chess) {
          return;
        }
        if (
          gridSelection.selectionMode !== 'risers' ||
          !gridSelection.selection.length ||
          gridSelection.selection.length > 1
        ) {
          console.error(
            'Нельзя удалить стояк т.к. стояк не выбран или выбрано больше одного стояка'
          );

          return;
        }

        const deletedRiser = gridSelection.selection[0];
        const deletedLotsIds = gridSelection.selectedLots.map(({ id }) => id);

        const { updatedRisers, updatedLots } = deleteRiser(
          chess.lots,
          chess.risers,
          deletedRiser,
          deletedLotsIds
        );

        updateEntrance({
          ...entrance,
          riserCount: (Number(entrance.riserCount) - 1).toString(),
        }).unwrap();

        await updateChess({
          chess: {
            entranceId: entrance.id,
            risers: updatedRisers,
            floors: chess.floors,
            lots: updatedLots,
          },
          objectId: Number(objectId),
          buildingId: Number(buildingId),
        }).unwrap();
        clearSelection();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const handleDeleteFloor = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        if (!entrance || !chess) {
          return;
        }
        if (
          gridSelection.selectionMode !== 'floors' ||
          !gridSelection.selection.length ||
          gridSelection.selection.length > 1
        ) {
          console.error(
            'Нельзя удалить этаж т.к. этаж не выбран или выбрано больше одного этажа'
          );

          return;
        }

        const deletedFloor = gridSelection.selection[0];
        const deletedLotsIds = gridSelection.selectedLots.map(({ id }) => id);

        const { updatedLots, updatedFloors } = deleteFloor(
          chess.lots,
          chess.floors,
          deletedFloor,
          deletedLotsIds
        );

        updateEntrance({
          ...entrance,
          floorCount: (Number(entrance.floorCount) - 1).toString(),
        }).unwrap();

        await updateChess({
          chess: {
            entranceId: entrance.id,
            risers: chess.risers,
            floors: updatedFloors,
            lots: updatedLots,
          },
          objectId: Number(objectId),
          buildingId: Number(buildingId),
        }).unwrap();
        clearSelection();
      } catch (error) {
        console.error(error);
        showSnackbar();
      }
    }
  };

  const dialogOptions = useMemo(() => {
    const dialogOptions = getLotsDialogOptions(
      gridSelection,
      chess?.lots || [],
      t,
      entrance
    );

    return dialogOptions;
  }, [chess?.lots, entrance, gridSelection, t]);

  const selectAll = () => {
    const allLotsIds = chess?.lots.map(({ id }) => id) ?? [];

    setGridSelection({
      selection: allLotsIds,
      selectionMode: 'lots',
      selectedLots: getSelectedLots('lots', allLotsIds, chess?.lots ?? []),
    });
  };

  const emptyLots =
    chess?.lots.filter((lot) => {
      const isValidLot = lotZod.safeParse(lot).success;
      return !isValidLot;
    }) ?? [];

  const forSaleLots =
    chess?.lots.filter(({ status }) => status === '5' || status === '6') ?? [];

  const selectMany = useCallback(
    (selectedLots: Lot[]) => () => {
      const lotsIds = selectedLots.map(({ id }) => id);

      setGridSelection({
        selection: lotsIds,
        selectionMode: 'lots',
        selectedLots: selectedLots,
      });
    },
    []
  );
  const handleOpenFiltersDialog = () => {
    clearSelection();
    setOpenFiltersDialog(true);
  };

  if (!entranceId || !objectId || !buildingId) {
    navigate(getRoute.Objects());
    return null;
  }

  if (!entrance || isLotsFetching) return <ChessboardSkeleton />;

  if (!chess) {
    return <div>Нет шахматки</div>;
  }

  return (
    <>
      {showVersionChangedPopup()}
      <Container sx={{ mb: 8 }}>
        <FiltersBar
          lotsFilters={lotsFilters}
          onSelectAllClick={selectAll}
          showSelectEmpty={emptyLots.length > 0}
          onSelectEmptyClick={selectMany(emptyLots)}
          showSelectForSale={forSaleLots.length > 0}
          onSelectForSaleClick={selectMany(forSaleLots)}
          onSetFiltersClick={handleOpenFiltersDialog}
          onDeleteFilterClick={deleteFilter}
        />
        {openFiltersDialog && (
          <FiltersDialog
            open={openFiltersDialog}
            filters={lotsFilters}
            onClearFiltersClick={clearFilters}
            onSaveClick={handleSaveFilters}
            onCancelClick={() => setOpenFiltersDialog(false)}
          />
        )}
      </Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: `${EDIT_BAR_AND_CHESS_GAP}px`,
          mb: `${CHESS_MARGIN_BOTTOM}px`,
          position: 'relative',
          maxWidth: isFullscreen ? '95%' : '100%',
          ...(isFullscreen
            ? {
                position: 'absolute',
                pt: 5,
                top: 0,
                bgcolor: BgColor.Gray,
                minHeight: '100%',
              }
            : {}),
        }}
      >
        <EditBar
          title={dialogOptions.title}
          subtitle={dialogOptions.subtitle}
          allLots={chess.lots}
          selectedLots={gridSelection.selectedLots}
          showMergeButton={dialogOptions.showMergeButtonInDialog}
          showSplitButton={dialogOptions.showSplitButtonInDialog}
          showDeleteRiserButton={dialogOptions.showDeleteRiserButton}
          showDeleteFloorButton={dialogOptions.showDeleteFloorButton}
          isCottageVillageOrTownhouse={Boolean(object?.objectType === '2')}
          onDeleteRiserClick={handleDeleteRiser}
          onDeleteFloorClick={handleDeleteFloor}
          onMergeClick={handleMergeClick}
          onDisabledChange={handleDisabledChange}
          onSaveClick={handleSaveDialogClick}
          onCancelClick={handleCancelDialogClick}
          onSplitClick={handleSplitClick}
          onEditBarHeightChange={setEditBarHeight}
          isFullscreen={isFullscreen}
          onCloseFullscreen={onCloseFullscreen}
        />
        <LotsGrid<Lot>
          lots={chess.lots}
          floors={chess.floors}
          risers={chess.risers}
          filteredLotsIds={filteredLotsIds}
          onAddRiser={isSomeLoading ? undefined : handleAddRiser}
          onAddFloor={isSomeLoading ? undefined : handleAddFloor}
          selectedLots={gridSelection.selectedLots.map(({ id }) => id)}
          selectedFloors={
            gridSelection.selectionMode === 'floors'
              ? gridSelection.selection
              : []
          }
          selectedRisers={
            gridSelection.selectionMode === 'risers'
              ? gridSelection.selection
              : []
          }
          onFloorCellClick={handleFloorCellClick}
          onRiserCellClick={handleRiserCellClick}
          onRiserRename={handleRiserRename}
          onFloorRename={handleFloorRename}
          onLotClick={handleLotClick}
          editBarHeight={editBarHeight}
          isMdDown={isMdDown}
          editBarAndChessSpacingSum={
            EDIT_BAR_AND_CHESS_GAP + CHESS_MARGIN_BOTTOM
          }
        />
      </Box>
      <Backdrop
        sx={{
          color: TextColor.StaticWhite,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
        open={isChessSaving}
      >
        <Spinner color={TextColor.StaticWhite} />
        <Typography>Идёт сохранение...</Typography>
      </Backdrop>
    </>
  );
};
